import { ReactNode } from "react";

import { css } from "@emotion/react";

import { uiGray } from "constants/colors";

import { LoadingCard } from "./LoadingCard";

const style = {
  background: css({
    background: uiGray[10],
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  }),
  embedded: css({ position: "fixed", bottom: 24 }),
};

export const LoadingFullScreen = ({
  text,
  embeddedComponent,
}: {
  text?: string;
  embeddedComponent?: ReactNode;
}) => {
  return (
    <div css={style.background}>
      <LoadingCard text={text} />
      <div css={style.embedded}>{embeddedComponent}</div>
    </div>
  );
};
