import { BarChart10Icon } from "Icons/BarChart10";
import { Grid01Icon } from "Icons/Grid01";
import { Key01Icon } from "Icons/Key01";
import { Lock01Icon } from "Icons/Lock01";
import { MessageSmileCircleIcon } from "Icons/MessageSmileCircle";
import { PuzzlePieceIcon } from "Icons/PuzzlePiece";
import { Recording02Icon } from "Icons/Recording02";
import { SettingsIcon } from "Icons/Settings";
import { SwitchHorizontal02Icon } from "Icons/SwitchHorizontal02";
import { Users02Icon } from "Icons/Users02";

import { css } from "@emotion/react";

import { useAccount } from "contexts/AccountContext";
import { usePageLayoutContext } from "contexts/PageLayoutContext";
import { useRecruitPlanContext } from "contexts/PlanGatingContext";

import { down } from "utils/mediaQueryStrings";
import {
  getApiKeysUrl,
  getGenieOverviewUrl,
  getHomeUrl,
  getMetricsUrl,
  getRecruitIntegrationsUrl,
  getRecruitInterviewsUrl,
  getRecruitSettingsUrl,
  getRecruitUrl,
  getTalentHubUrl,
} from "utils/urls";

import { purple } from "constants/colors";

import { Authenticated } from "../library/Authenticated";
import { ButtonLink } from "../library/ButtonLink";
import { Divider } from "../library/Divider";
import { SidePanel } from "./SidePanel";
import { SidePanelItem } from "./SidePanelItem";
import { SignUpWidget } from "./SignUpWidget";
import { UpgradeSection } from "./UpgradeSection";
import { UserAccountDisplay } from "./UserAccountDisplay";

const getStyles = (collapsed: boolean) => {
  return {
    content: css({
      display: "flex",
      flexDirection: "column",
      width: collapsed ? 36 : 240,
    }),
    userAccount: css({
      padding: !collapsed ? 0 : "0px 0px 16px 0px",
      [down("mobile")]: {
        marginTop: 24,
        padding: 0,
      },
    }),
    sidePanelItems: css({
      display: "flex",
      flexDirection: "column",
      gap: 8,
      marginTop: collapsed ? 12 : 0,
    }),
    bottomDiv: css({
      marginTop: "auto",
      display: "flex",
      flexDirection: "column",
      gap: 8,
      width: collapsed ? 36 : 240,
    }),
    divider: css({ margin: "16px 0" }),
  };
};

export const RecruitSidePanel = () => {
  const { leftPanelStatus } = usePageLayoutContext();
  const { plan } = useRecruitPlanContext();
  const { accountProfile } = useAccount(false);
  const style = getStyles(leftPanelStatus === "collapsed");

  return (
    <SidePanel>
      <div css={style.content}>
        <Authenticated>
          <div css={style.userAccount}>
            <UserAccountDisplay
              disableClick
              showAvatarOnly={leftPanelStatus === "collapsed"}
            />
          </div>
        </Authenticated>
        <div css={style.sidePanelItems}>
          <Authenticated>
            <SidePanelItem
              label="Dashboard"
              link={getRecruitUrl()}
              Icon={Grid01Icon}
              disableChildPathAsActive
            />
          </Authenticated>
          <Authenticated>
            <SidePanelItem
              label="Interviews"
              link={getRecruitInterviewsUrl()}
              Icon={Recording02Icon}
            />
          </Authenticated>
          <SidePanelItem
            label="Talent Hub"
            link={getTalentHubUrl()}
            Icon={Users02Icon}
          />
          <Authenticated>
            <SidePanelItem
              label="Talent Genie"
              link={getGenieOverviewUrl()}
              Icon={MessageSmileCircleIcon}
            />
          </Authenticated>
          <Authenticated>
            <SidePanelItem
              label="Metrics"
              link={getMetricsUrl()}
              Icon={BarChart10Icon}
              isNew
            />
          </Authenticated>
          <Divider customCss={style.divider} />
          <Authenticated>
            <SidePanelItem
              label="Integrations"
              link={getRecruitIntegrationsUrl()}
              Icon={PuzzlePieceIcon}
              RightComponent={
                plan && !plan.hasIntegrationsAccess ? (
                  <Lock01Icon stroke={purple[600]} />
                ) : undefined
              }
            />
          </Authenticated>
          <Authenticated>
            <SidePanelItem
              label="API keys"
              link={getApiKeysUrl()}
              Icon={Key01Icon}
            />
          </Authenticated>
          <Authenticated>
            <SidePanelItem
              label="Settings"
              link={getRecruitSettingsUrl()}
              Icon={SettingsIcon}
            />
          </Authenticated>
        </div>
      </div>
      <div css={style.bottomDiv}>
        {!(leftPanelStatus === "collapsed") && (
          <>
            <Authenticated>
              <UpgradeSection />
            </Authenticated>
            {!accountProfile?.id && <SignUpWidget />}
            <ButtonLink
              href={getHomeUrl()}
              background="ghost"
              color="gray"
              sameTab
              IconLeft={SwitchHorizontal02Icon}
            >
              Switch to Jobseeker mode
            </ButtonLink>
          </>
        )}
      </div>
    </SidePanel>
  );
};
