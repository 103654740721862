import { LoadingAltIcon } from "Icons/LoadingAlt";

import { useEffect } from "react";

import { css } from "@emotion/react";

import { logger } from "interfaces/logger";

import { uiGray, white } from "constants/colors";
import { errorLogTime } from "constants/time";

import { SpinningIcon } from "./SpinningIcon";
import { Typography } from "./Typography";

const style = {
  card: css({
    margin: "auto",
    boxSizing: "border-box",
    width: 239,
    height: 206,
    padding: 48,
    background: white,
    border: `1px solid ${uiGray[10]}`,
    boxShadow:
      "0px 8px 48px -16px rgba(36, 20, 114, 0.04), 0px 0px 32px rgba(36, 20, 114, 0.02)",
    borderRadius: 24,
    display: "flex",
    flexDirection: "column",
    gap: 12,
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  }),
  heartIcon: css({
    position: "absolute",
    top: -23,
    left: 198,
  }),
  ribbonIcon: css({
    position: "absolute",
    top: 110,
    left: -40,
  }),
  line: css({
    position: "absolute",
    top: 140,
    left: -5,
    width: "100%",
    height: "100%",
    display: "flex",
  }),
  lineSvg: css({
    maskType: "alpha",
  }),
};

export const LoadingCard = ({ text = "Loading" }: { text?: string }) => {
  // log error if the component is shown for more than X seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      logger(
        `Loading component is shown for more than ${errorLogTime} milliseconds`,
        "error"
      );
    }, errorLogTime);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div css={style.card}>
      <SpinningIcon
        Icon={LoadingAltIcon}
        stroke={uiGray[90]}
        width={30}
        height={30}
      />
      <Typography size="XL" weight="bold">
        {text}
      </Typography>
    </div>
  );
};
