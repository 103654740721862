import { IconProps } from "Icons/types";

import { uiGray } from "constants/colors";

export const SuperThumbsDown2Icon = ({
  width = 24,
  height = 24,
  stroke = uiGray[90],
}: IconProps) => (
  <svg
    className="hire_vote"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.103 15.2L18.103 7.5L20.203 7.5C20.9762 7.5 21.603 8.1268 21.603 8.9L21.603 13.8C21.603 14.5732 20.9762 15.2 20.203 15.2L18.5579 15.2C18.2813 15.2 18.0306 15.3629 17.9183 15.6157L15.4549 21.1583C15.3625 21.3661 15.1565 21.5 14.9291 21.5C13.9758 21.5 13.203 20.7272 13.203 19.7739L13.203 17.3C13.203 16.9134 12.8896 16.6 12.503 16.6"
      stroke={stroke}
      stroke-width="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.103 2.5L14.103 11.3M18.103 9.7L18.103 4.1C18.103 3.21634 17.3867 2.5 16.503 2.5L5.76204 2.5C4.57746 2.5 3.57007 3.36426 3.38994 4.53506L2.5284 10.1351C2.30474 11.5889 3.42957 12.9 4.9005 12.9L7.70303 12.9C8.14486 12.9 8.50303 13.2582 8.50303 13.7L8.50303 16.5273C8.50303 17.6168 9.38622 18.5 10.4757 18.5C10.7356 18.5 10.971 18.347 11.0766 18.1095L13.8919 11.7751C14.0203 11.4862 14.3068 11.3 14.6229 11.3L16.503 11.3C17.3867 11.3 18.103 10.5837 18.103 9.7Z"
      stroke={stroke}
      stroke-width="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clip-path="url(#clip0_4732_82)">
      <path
        d="M3.29069 18.0073C3.35792 17.8711 3.39153 17.803 3.43716 17.7813C3.47686 17.7624 3.52299 17.7624 3.56269 17.7813C3.60832 17.803 3.64193 17.8711 3.70916 18.0073L4.34694 19.2994C4.36678 19.3396 4.37671 19.3597 4.39121 19.3753C4.40405 19.3891 4.41945 19.4003 4.43655 19.4083C4.45587 19.4173 4.47806 19.4205 4.52242 19.427L5.94904 19.6355C6.09927 19.6575 6.17439 19.6685 6.20915 19.7052C6.23939 19.7371 6.25362 19.7809 6.24786 19.8245C6.24124 19.8747 6.18686 19.9276 6.0781 20.0336L5.04617 21.0387C5.01401 21.07 4.99792 21.0856 4.98754 21.1043C4.97835 21.1208 4.97246 21.1389 4.97019 21.1577C4.96762 21.1789 4.97141 21.201 4.979 21.2452L5.22249 22.6649C5.24817 22.8146 5.26101 22.8895 5.23688 22.9339C5.21588 22.9726 5.17856 22.9997 5.13531 23.0077C5.0856 23.0169 5.01837 22.9816 4.88391 22.9109L3.60853 22.2401C3.56879 22.2192 3.54892 22.2088 3.52799 22.2047C3.50946 22.2011 3.49039 22.2011 3.47186 22.2047C3.45093 22.2088 3.43106 22.2192 3.39132 22.2401L2.11594 22.9109C1.98148 22.9816 1.91425 23.0169 1.86454 23.0077C1.82128 22.9997 1.78396 22.9726 1.76297 22.9339C1.73884 22.8895 1.75168 22.8146 1.77736 22.6649L2.02084 21.2452C2.02843 21.201 2.03223 21.1789 2.02966 21.1577C2.02739 21.1389 2.02149 21.1208 2.0123 21.1043C2.00193 21.0856 1.98584 21.07 1.95367 21.0387L0.921748 20.0336C0.812987 19.9276 0.758606 19.8747 0.751989 19.8245C0.746231 19.7809 0.760455 19.7371 0.7907 19.7052C0.825462 19.6685 0.900577 19.6575 1.05081 19.6355L2.47743 19.427C2.52179 19.4205 2.54398 19.4173 2.56329 19.4083C2.5804 19.4003 2.5958 19.3891 2.60864 19.3753C2.62314 19.3597 2.63306 19.3396 2.65291 19.2994L3.29069 18.0073Z"
        stroke={"#AA2B30"}
        fill={"#AA2B30"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4732_82">
        <rect width="7" height="7" fill="white" transform="translate(0 17)" />
      </clipPath>
    </defs>
  </svg>
);
